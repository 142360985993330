import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/style.css';

import { createAuth0 } from '@auth0/auth0-vue';
createApp(App)
  .use(router)
  .use(createAuth0({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
    },
    cacheLocation: 'localstorage', // or 'memory' based on your preference
    useRefreshTokens: true, // Enables the use of refresh tokens for silent authentication
    ui_locales: 'es',
  })
  )
  .mount('#app');