<template>
  <main class="flex p-4 pt-0 lg:ml-56 bg-gray-100" style="min-height: calc(85vh); max-height: calc(85vh);">

    <div v-if="loading" class="flex justify-center items-center text-center mx-auto w-full h-full">
      <i class="fas fa-spinner fa-spin text-4xl text-blue-500 text-center mx-auto"></i>
    </div>
    <!-- height: calc(85vh); width: calc(100vh); -->
    <div v-else class="rounded-2xl w-full bg-white"
      style="flex: 1; scrollbar-width: none; -ms-overflow-style: none; overflow: auto;">
      <table class="w-full">
        <thead>
          <tr class="border-b">
            <!-- Buscador ID -->
            <th class="w-1/7 pl-6 pt-5 pb-3 text-left text-sm">
              <div class="flex items-center">
                <i class="fas fa-search mr-2 text-blue-400 cursor-pointer" @click="focusSearchInput"></i>
                <input type="text" v-model="searchTerm" @input="debouncedSearchDocuments"
                  class="text-gray-400 text-lg focus:outline-none" placeholder="Id Documento" ref="searchInput" />
              </div>
            </th>
            <!-- FIRMANTES -->
            <th class="w-1/7 pl-8 pt-5 pb-3 text-left text-sm">
              <p class="text-gray-400 text-lg">Firmantes</p>
            </th>
            <!-- Buscador fecha -->
            <th class="w-1/7 pt-5 pb-3 text-left text-sm">
              <div class="flex items-center">
                <input type="date" v-model="searchDate" @input="searchDocuments"
                  class="text-gray-400 text-lg focus:outline-none" placeholder="Fecha de Creación" />
              </div>
            </th>
            <!-- Buscador estado -->
            <th class="w-1/7 pt-5 pb-3 text-left text-sm relative" ref="dropdownContainer">
              <div class="flex items-center">
                <i class="fas fa-chevron-down mr-2 text-blue-400 cursor-pointer" @click="toggleDropdown"></i>
                <div class="relative">
                  <button class="text-gray-400 text-lg focus:outline-none" @click="toggleDropdown">Estado</button>
                  <div v-if="showDropdown"
                    class="dropdown-box absolute top-full left-0 z-50 bg-white border border-gray-300 shadow-md p-3">
                    <ul class="mt-2">
                      <li class="cursor-pointer px-4 py-2 hover:bg-gray-100" @click="setSearchStatus('Todos')">Todos
                      </li>
                      <li class="cursor-pointer px-4 py-2 hover:bg-gray-100" @click="setSearchStatus('Firmado')">Firmado
                      </li>
                      <li class="cursor-pointer px-4 py-2 hover:bg-gray-100" @click="setSearchStatus('Pendiente')">
                        Pendiente</li>
                    </ul>
                  </div>
                </div>
              </div>
            </th>
            <!-- Etiquetas -->
            <th class="w-1/7 pt-5 pb-3 text-left text-sm relative" ref="dropdownEtiquetasContainer">
              <div class="flex items-center">
                <i class="fas fa-chevron-down mr-2 text-blue-400 cursor-pointer" @click="toggleDropdownEtiquetas"></i>
                <div class="relative">
                  <button class="text-gray-400 text-lg focus:outline-none"
                    @click="toggleDropdownEtiquetas">Etiquetas</button>
                  <div v-if="showDropdownEtiquetas"
                    class="dropdown-box absolute top-full left-0 z-50 border border-gray-300 shadow-md p-3">
                    <ul class="mt-2">
                      <li v-for="(label, index) in labels" :key="index"
                        :class="['cursor-pointer px-4 py-2 transition-all duration-300', label.hover ? 'brightness-150' : '']"
                        :style="{ backgroundColor: label.color }" @click="handleLabelClick(label.label_id)"
                        @mouseenter="label.hover = true" @mouseleave="label.hover = false">
                        {{ label.label_name }}
                      </li>
                    </ul>
                    <div v-if="labels.length < 7"
                      class="flex items-center justify-center h-6 w-12 border border-gray-300 cursor-pointer"
                      @click="addLabel">
                      <i class="fas fa-plus text-green-500"></i>
                    </div>
                    <!-- Message if 6 labels have already been added -->
                    <div v-else class="text-red-500 text-sm mt-2"></div>
                  </div>
                </div>
              </div>
            </th>

            <!-- Informe -->
            <th class="w-1/7 pr-10 pt-5 pb-3 text-left text-sm">
              <p class="text-gray-400 text-lg focus:outline-none">Informe</p>
            </th>

            <!-- DELETE -->
            <th class="w-1/7 pr-10 pt-5 pb-3 text-left text-sm">
              <p class="text-gray-400 text-lg focus:outline-none">Eliminar</p>
            </th>

          </tr>
        </thead>
        <tbody>
          <tr class="border-b" v-for="(solicitud, index) in todasLasSolicitudes" :key="'row-' + index">
            <td class="text-sm pl-6 py-2 flex items-center">
              <div>
                <!-- Row Header -->
                <div @click="toggleRow(index)" class="flex items-end cursor-pointer">
                  {{ solicitud.id_custom_client }}.pdf
                  <i class="fas fa-chevron-down text-blue-400 ml-2 mb-0.5"
                    :class="{ 'transform rotate-180': isRowExpanded(index) }"></i>
                </div>

                <!-- Signers Details -->
                <div v-show="isRowExpanded(index)" class="grid grid-cols-2 gap-4 overflow-x-auto">
                  <!-- First Column -->
                  <div class="flex flex-col mt-2">
                    <div
                      v-for="(firmante, firmanteIndex) in solicitud.firmantes.slice(0, Math.ceil(solicitud.firmantes.length / 2))"
                      :key="'firmante-' + firmanteIndex" class="flex flex-col">
                      <span>
                        <i class="fas fa-circle mr-2"
                          :class="{ 'text-green-400': firmante.signed, 'text-gray-400': !firmante.signed }"
                          :title="firmante.signed ? 'Firmado' : 'Pendiente a firmar'"></i>
                        {{ firmante.name || 'Sin nombre' }}
                      </span>
                      <div class="flex items-center mt-1">
                        <a :href="firmante.link" target="_blank" class="hover:text-blue-400 underline">
                          {{ firmante.link || 'Sin enlace' }}
                        </a>
                        <i v-if="firmante.link && !copiedLinks.includes(firmante.link)" @click="copyLink(firmante.link)"
                          class="fas fa-clone ml-2 mr-1 text-blue-400 hover:text-blue-300 cursor-pointer"></i>
                        <i v-else-if="firmante.link" class="fas fa-check-circle ml-2 mr-1 text-green-400"></i>
                      </div>
                      <p class="mt-1 mb-2">
                        {{ firmante.method || 'Sin método' }}
                        <span class="ml-4">
                          {{ isNaN(firmante.phone?.charAt(0)) ? firmante.phone : '+' + firmante.phone || firmante.email
                          || 'Sin contacto' }}
                        </span>
                        <!-- Delivered Status -->
                        <span v-if="firmante.delivered" class="fas fa-check-double text-blue-500 ml-2"
                          :title="'Mensaje entregado'"></span>
                        <span v-else class="fas fa-times text-red-500 ml-2" :title="'Mensaje no entregado'"></span>
                        <!-- DNI/CI -->
                        <span class="ml-4">
                          | DNI/CI: {{ firmante.dni || 'No disponible' }}
                        </span>
                      </p>
                    </div>
                  </div>

                  <!-- Second Column -->
                  <div class="flex flex-col mt-2">
                    <div
                      v-for="(firmante, firmanteIndex) in solicitud.firmantes.slice(Math.ceil(solicitud.firmantes.length / 2))"
                      :key="'firmante-' + (firmanteIndex + Math.ceil(solicitud.firmantes.length / 2))"
                      class="flex flex-col">
                      <span>
                        <i class="fas fa-circle mr-2"
                          :class="{ 'text-green-400': firmante.signed, 'text-gray-400': !firmante.signed }"
                          :title="firmante.signed ? 'Firmado' : 'Pendiente a firmar'"></i>
                        {{ firmante.name || 'Sin nombre' }}
                      </span>
                      <div class="flex items-center mt-1">
                        <a :href="firmante.link" target="_blank" class="hover:text-blue-400 underline">
                          {{ firmante.link || 'Sin enlace' }}
                        </a>
                        <i v-if="firmante.link && !copiedLinks.includes(firmante.link)" @click="copyLink(firmante.link)"
                          class="fas fa-clone ml-2 mr-1 text-blue-400 hover:text-blue-300 cursor-pointer"></i>
                        <i v-else-if="firmante.link" class="fas fa-check-circle ml-2 mr-1 text-green-400"></i>
                      </div>
                      <p class="mt-1 mb-2">
                        {{ firmante.method || 'Sin método' }}
                        <span class="ml-4">
                          {{ isNaN(firmante.phone?.charAt(0)) ? firmante.phone : '+' + firmante.phone || firmante.email
                          || 'Sin contacto' }}
                        </span>
                        <!-- Delivered Status -->
                        <span v-if="firmante.delivered" class="fas fa-check-double text-blue-500 ml-2"
                          :title="'Mensaje entregado'"></span>
                        <span v-else class="fas fa-times text-red-500 ml-2" :title="'Mensaje no entregado'"></span>
                        <!-- DNI/CI -->
                        <span class="ml-4">
                          | DNI/CI: {{ firmante.dni || 'No disponible' }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>



              </div>
            </td>

            <!-- FIRMANTES -->
            <td class="pl-16 pt-2 text-sm" style="vertical-align: top;">
              <p>{{ solicitud.cantidad_firmados }}/{{ solicitud.cantidad_firmantes }}</p>
            </td>

            <td class="pl-8 pt-2 text-sm" style="vertical-align: top;">
              {{ formatDate(solicitud.created_at) }}
            </td>

            <!-- ESTADO -->
            <td class="pt-2 pl-2 text-sm " style="vertical-align: top;">
              <p :class="{ 'bg-green-400': solicitud.is_finished, 'bg-gray-400': !solicitud.is_finished }"
                class="text-white font-semibold px-0 py-0.5 rounded-xl mr-4 w-20 text-center">
                {{ solicitud.is_finished ? 'Firmado' : 'Pendiente' }}
              </p>
            </td>


            <!-- ETIQUETAS -->
            <td class="pt-2 pl-3 text-sm" style="vertical-align: top; position: relative;">
              <button @click="toggleDropdownIndex(index)"
                class="font-semibold px-0 py-0.5 rounded-xl mr-4 w-20 text-center" :style="{
                  backgroundColor: solicitud.label.color || 'white',
                  color: solicitud.label.label_name ? 'black' : '#4ADE80'
                }">
                {{ solicitud.label.label_name || '+' }}
              </button>
              <div :ref="`dropdown-${index}`" v-if="solicitud.showDropdown" class="dropdown-box mt-2 p-4">
                <ul class="mt-2">
                  <li v-for="(label, labelIndex) in labels" :key="labelIndex" :style="{ backgroundColor: label.color }"
                    class="cursor-pointer px-4 py-2 transition-all duration-300 hover:brightness-150"
                    @click="changeLabel(label.label_id, label.label_name, solicitud)">
                    {{ label.label_name }}
                  </li>
                </ul>
              </div>
            </td>

            <!-- INFORME -->
            <td class="pl-6 text-sm" style="vertical-align: top;">
              <a :href="solicitud.is_finished ? `https://api.wapifirma.com/api/reporte/${solicitud.id_seguimiento}` : 'javascript:void(0);'"
                :class="solicitud.is_finished ? 'text-blue-400 hover:text-blue-300' : 'text-gray-400 cursor-not-allowed'"
                :target="solicitud.is_finished ? '_blank' : ''">
                <i class="far fa-file-pdf text-3xl"
                  :class="solicitud.is_finished ? 'text-blue-400 hover:text-blue-300' : 'text-gray-400'"></i>
              </a>
            </td>

            <!-- DELETE -->
            <td class="pl-6 text-sm" style="vertical-align: top;">
              <a v-if="solicitud.cantidad_firmados === 0" @click="deleteDocument(solicitud.id_seguimiento)"
                class="text-red-400 cursor-pointer">
                <i class="fas fa-trash-alt project-item hover-enabled mt-1" style="font-size: 24px;"></i>
              </a>
              <a v-else class="text-gray-400 cursor-not-allowed">
                <i class="fas fa-trash-alt project-item mt-1" style="font-size: 24px;"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
  <div class="justify-center mx-auto text-xl rounded-md text-white flex lg:ml-56">
    <button @click="prevPage" class="focus:outline-none">
      <i class="fas fa-chevron-left text-blue-500 hover:text-blue-700"></i>
    </button>
    <p class="mx-4 text-black">{{ currentPage }}</p>
    <button @click="nextPage" class="focus:outline-none">
      <i class="fas fa-chevron-right text-blue-500 hover:text-blue-700"></i>
    </button>
  </div>
  <!-- flex p-4 pt-0 lg:ml-56 bg-gray-100 -->

  <PopUpComponent :show="showPopUp" :message="PopUpMessage" />


</template>

<script>
import PopUpComponent from '../components/PopUpComponent.vue';
import axios from 'axios';
import { getCookie } from '../helpers/cookies';

export default {
  emits: ['login', 'logout'],
  props: ['user'],
  components: {
    PopUpComponent
  },
  data() {
    return {
      loading: false,
      showDropdown: false,
      showDropdownEtiquetas: false,
      showMessage: false,
      sidebarOpen: false,
      documentosFirmados: [],
      documentoStatus: null,
      solicitudesGrupo: [],
      todasLasSolicitudes: [],
      todasLasSolicitudesOriginal: [],
      searchDate: '',
      searchTerm: '',
      searchLabel: '',
      searchStatus: null,
      expandedRows: [],
      copiedLinks: [],
      showPopUp: false,
      PopUpMessage: 'Documento Enviado',
      currentPage: 1,
      labels: [{ label_name: 'Todos', color: '#F3F4F6', label_id: 0 }],
      userLabels: [{ name: '', backgroundColor: '#FF9803' }, { name: '', backgroundColor: '#E82063' }, { name: '', backgroundColor: '#9C27B3' }, { name: '', backgroundColor: '#2396F3' }, { name: '', backgroundColor: '#4DB050' }, { name: '', backgroundColor: '#FFEB3C' }],
      colors: ['#FF9803', '#E82063', '#9C27B3', '#2396F3', '#4DB050', '#FFEB3C'],
    };
  },
  created() {
    this.fetchTodasLasSolicitudes();
    const enviado = this.$route.query.enviado; // Mostrar y cerrar popup
    if (enviado === 'true') {
      this.showPopUp = true;
      setTimeout(() => {
        this.showPopUp = false;
      }, 5000);
    }
  },
  methods: {
    logout() {
      this.$emit('logout');
    },

    deleteDocument(id_seguimiento) {
      const confirmation = confirm('¿Estás seguro de que quieres borrar este documento?');

      if (confirmation) {
        const headers = {
          'Content-Type': 'application/json',
          'x-api-key': getCookie('token')
        };
        axios.delete(`${process.env.VUE_APP_API_URL}/api/deleteDocument/${id_seguimiento}`, { headers })
          .then(() => {
            this.todasLasSolicitudes = this.todasLasSolicitudes.filter(solicitud => solicitud.id_seguimiento !== id_seguimiento);
          })
          .catch(error => {
            console.error('Error al borrar el documento:', error);
          });
      }
    },



    toggleDropdownIndex(index) {
      // Close any open dropdowns except the one being clicked
      this.todasLasSolicitudes.forEach((solicitud, i) => {
        if (i !== index) {
          solicitud.showDropdown = false;
        }
      });

      // Toggle the dropdown for the current row
      this.todasLasSolicitudes[index].showDropdown = !this.todasLasSolicitudes[index].showDropdown;

      // Add or remove the event listener based on the dropdown state
      if (this.todasLasSolicitudes[index].showDropdown) {
        this.$nextTick(() => {
          document.addEventListener('click', this.closeDropdownOnClickOutsideIndex);
        });
      } else {
        document.removeEventListener('click', this.closeDropdownOnClickOutsideIndex);
      }
    },

    closeDropdownOnClickOutsideIndex(event) {
      let dropdownOpen = false;

      this.todasLasSolicitudes.forEach((solicitud, index) => {
        const dropdownRef = this.$refs[`dropdown-${index}`];

        if (Array.isArray(dropdownRef)) {
          dropdownRef.forEach(ref => {
            if (ref instanceof HTMLElement) {
              if (!ref.contains(event.target) && !ref.parentElement.contains(event.target)) {
                solicitud.showDropdown = false;
              } else {
                dropdownOpen = true;
              }
            }
          });
        } else if (dropdownRef instanceof HTMLElement) {
          console.log(`Dropdown ref for ${index}:`, dropdownRef);
          if (!dropdownRef.contains(event.target) && !dropdownRef.parentElement.contains(event.target)) {
            solicitud.showDropdown = false;
          } else {
            dropdownOpen = true;
          }
        }
      });

      if (!dropdownOpen) {
        document.removeEventListener('click', this.closeDropdownOnClickOutsideIndex);
      }
    },




    changeLabel(label_id, label_name, solicitud) {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': getCookie('token')
      };
      axios.put(`${process.env.VUE_APP_API_URL}/api/putEtiqueta`, { id_seguimiento: solicitud.id_seguimiento, label_id: label_id }, { headers })
        .then(() => {


          solicitud.label = { label_name: label_name, color: this.labels.find(label => label.label_id === label_id).color };
          solicitud.showDropdown = false;
        })
        .catch(error => {
          console.error('Error al cambiar la etiqueta:', error);
        });
    },
    handleLabelClick(label_id) {

      this.searchLabel = label_id;

      this.showDropdownEtiquetas = false;

      this.searchDocuments();
    },
    addLabel() {
      if (this.labels.length < 7) {
        const newLabel = prompt('Enter new label:');
        if (newLabel) {
          const color = this.colors[this.labels.length];
          this.labels.push({ label_name: newLabel, color: color, label_id: this.labels.length + 1 });

          // Save the new label to the database
          const headers = {
            'Content-Type': 'application/json',
            'x-api-key': getCookie('token')
          };
          axios.put(`${process.env.VUE_APP_API_URL}/api/addEtiqueta`, { label_name: newLabel }, { headers })
            .then(response => {
              console.log('Etiqueta añadida:', response.data);
            })
            .catch(error => {
              console.error('Error al añadir la etiqueta:', error);
            });
        }
      }
    },
    toggleDropdown() { // Dropdown methods
      this.showDropdown = !this.showDropdown;

      if (this.showDropdown) {
        this.$nextTick(() => {
          document.addEventListener('click', this.closeDropdownOnClickOutside);
        });
      } else {
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
      }
    },
    toggleDropdownEtiquetas() {
      this.showDropdownEtiquetas = !this.showDropdownEtiquetas;
      if (this.showDropdownEtiquetas) {
        this.$nextTick(() => {
          document.addEventListener('click', this.closeDropdownOnClickOutside);
        });
      } else {
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
      }
    },
    closeDropdownOnClickOutside(event) {
      if (this.$refs.dropdownEtiquetasContainer && !this.$refs.dropdownEtiquetasContainer.contains(event.target)) {
        this.showDropdownEtiquetas = false;
      }
      if (this.$refs.dropdownContainer && !this.$refs.dropdownContainer.contains(event.target)) {
        this.showDropdown = false;
      }

    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    toggleRow(index) { // Método para alternar la expansión de filas
      if (this.expandedRows.includes(index)) {
        // Si ya está expandido, lo cerramos
        this.expandedRows = this.expandedRows.filter(i => i !== index);
      } else {
        // Si no está expandido, lo abrimos
        this.expandedRows.push(index);
      }
    },
    isRowExpanded(index) { // Método para verificar si una fila está expandida
      return this.expandedRows.includes(index);
    },
    copyLink(link) { // Método para copiar el link
      const el = document.createElement('textarea');
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copiedLinks.push(link);

      setTimeout(() => { // Eliminar el enlace copiado después de dos segundos
        const index = this.copiedLinks.indexOf(link);
        if (index !== -1) {
          this.copiedLinks.splice(index, 1);
        }
      }, 2000);
    },
    formatDate(unixTimestamp) {
      const date = new Date(unixTimestamp * 1000); // Convert UNIX timestamp to milliseconds
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    formatDateToDDMMYYYY(dateString) {
      const [year, month, day] = dateString.split('-');
      return `${day}-${month}-${year}`;
    },
    setSearchStatus(status) {
      this.searchStatus = status === 'Firmado';
      if (status === 'Todos') {
        this.searchStatus = null;
      }
      this.showDropdown = false;
      this.searchDocuments();
    },
    focusSearchInput() {
      this.$refs.searchInput.focus();
    },
    debouncedSearchDocuments() {
      // Clear the previous timer if the user keeps typing
      clearTimeout(this.debounceTimer);

      // Set a new timer to call searchDocuments after 2 seconds
      this.debounceTimer = setTimeout(() => {
        this.searchDocuments();
      }, 2000);
    },

    async searchDocuments() {
      const searchTerm = this.searchTerm.trim();
      const searchDate = this.searchDate;
      const searchStatus = this.searchStatus;
      const searchLabel = this.searchLabel;


      // Si tanto el término de búsqueda como la fecha de búsqueda están vacíos, mostrar todas las solicitudes
      if (!searchTerm && !searchDate && searchStatus === null && !searchLabel) {
        this.todasLasSolicitudes = [...this.todasLasSolicitudesOriginal];
        return;
      }

      this.todasLasSolicitudes = await this.fetchFilter(searchTerm, searchDate, searchStatus, searchLabel);
    },
    async fetchFilter(searchTerm, searchDate, searchStatus, searchLabel) {
      this.loading = true;
      this.currentPage = 1;

      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': getCookie('token')
      };

      axios.get(`${process.env.VUE_APP_API_URL}/api/list?page=${this.currentPage}&id_custom_client=${searchTerm}&is_finished=${searchStatus}&date=${searchDate}&label_id=${searchLabel}`, { headers })
        .then(response => {
          this.todasLasSolicitudes = response.data.splice(0, 15);
          this.loading = false;
        })
        .catch(error => {
          console.error('Error al obtener todas las solicitudes:', error);
          this.loading = false;
        });

    },
    fetchTodasLasSolicitudes() {
      this.loading = true;
      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': getCookie('token')
      };

      axios.get(`${process.env.VUE_APP_API_URL}/api/list?page=${this.currentPage}`, { headers })
        .then(response => {
          this.todasLasSolicitudes = response.data.splice(0, 15);
          this.todasLasSolicitudesOriginal = this.todasLasSolicitudes;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error al obtener todas las solicitudes:', error);
          this.loading = false;
        });

      // Etiqeutas get
      axios.get(`${process.env.VUE_APP_API_URL}/api/listEtiquetas`, { headers })
        .then(response => {
          this.labels = [...this.labels, ...response.data];
        })
        .catch(error => {
          console.error('Error al obtener las etiquetas:', error);
        });
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber;

      this.fetchTodasLasSolicitudes();
    },
    nextPage() {
      if (this.todasLasSolicitudes.length === 15) {
        this.currentPage += 1;
        this.labels = [{ label_name: 'Todos', color: '#F3F4F6', label_id: 0 }];
        if (this.searchTerm || this.searchDate || this.searchStatus !== null) {
          this.searchDocuments();
        } else {
          this.fetchTodasLasSolicitudes();
        }
      }
    },
    prevPage() {
      this.labels = [{ label_name: 'Todos', color: '#F3F4F6', label_id: 0 }];
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.fetchTodasLasSolicitudes();
      }
    },
    beforeDestroy() {
      document.removeEventListener('click', this.closeDropdownOnClickOutside);
    }
  }
}
</script>


<!-- El icono de fecha -->
<style>
::-webkit-calendar-picker-indicator {
  margin-left: -15px;
  margin-top: 4px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%234285f4" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.dropdown-box {
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  /* Set the desired width */
  background-color: white;
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  z-index: 1000;
  /* Ensure it overlaps other elements */
  border: 1px solid #ddd;
  /* Optional border for better visibility */
}

.dropdown-box ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-box li {
  margin-bottom: 0.5rem;
  /* Adjust as needed */
  padding: 0.5rem 1rem;
  /* Adjust padding for spacing within items */
  border-radius: 0.25rem;
  /* Adjust as needed for rounded corners */
}

.project-item {
  transition: transform 0.2s ease, color 0.2s ease;
}

.project-item.hover-enabled {
  cursor: pointer;
}

.project-item.hover-enabled:hover {
  transform: scale(1.2);
  color: red;
}
</style>