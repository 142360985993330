<template>
  <!-- No UI needed, user will be redirected to Auth0 -->
  <div></div>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { onMounted } from "vue";

const { loginWithRedirect, isAuthenticated } = useAuth0();

onMounted(async () => {
  // Check if the user is already authenticated
  if (!isAuthenticated.value) {
    await loginWithRedirect({appState: { target: "/requests" },});
  }
});
</script>
