<template>
  <main class="flex p-4 pt-0 lg:ml-56 bg-gray-100">
    <div v-if="loading" class="flex justify-center w-full">
      <i class="fas fa-spinner fa-spin text-4xl text-blue-500"></i>
    </div>
    <div v-else class="rounded-2xl w-full bg-white" style="flex:1; height: calc(85vh); width: calc(100vh)">
      <div class="m-6 mt-4 ml-6">
        <div style="display: flex; width: 100%;">
          <h2 class="text-blue-500 font-bold mb-2" style="flex: 1; text-align: left;">Datos Empresa</h2>
          <h2 class="text-blue-500 font-bold mb-2" style="flex: 1; text-align: left;">Agregar colaborador</h2>
        </div>

        <div class="flex flex-col">
          <div class="flex items-center">
            <!-- First Input (Left Half) -->
            <div class="w-1/2">
              <input type="text" placeholder="Nombre" v-model="datosPersonales.client_business_name"
                class="border-gray-300 border-b-2 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800 w-1/2" />
              <!-- Info Icon with Title for Tooltip -->
              <i class="fas fa-info-circle text-xl mb-2 text-gray-500 hover:text-gray-400 cursor-pointer"
                title="Es el nombre con el cual los firmantes identifican a su empresa.
Lo verán de la siguiente manera:
¡Hola Darío! Soy WapIA, asistente virtual de WapiFirma. Crecer SA me pidió hacerle llegar el siguiente documento para que lo firme."></i>
            </div>



            <div class="w-1/2">
              <!-- Second Input (Right Half) -->
              <input type="text" placeholder="Mail" v-model="invite_mail"
                class="border-gray-300 border-b-2 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800 w-1/2">

              <i class="fas fa-info-circle text-xl text-gray-500 hover:text-gray-400 cursor-pointer"
                title="Agrega colaboradores para que puedan ver los mismos documentos"></i>

              <button @click="inviteCollaborator"
                :class="{ 'bg-blue-500': invite_mail, 'bg-gray-500': !invite_mail, 'cursor-pointer': invite_mail, 'cursor-not-allowed': !invite_mail }"
                :disabled="!invite_mail" class="text-white px-4 py-2 rounded-md ml-4">
                Agregar
              </button>
              <div v-if="errorColaborador"
                class="text-red-500 bg-red-100 p-2 rounded-md text-center flex justify-between items-center"
                style="width: 460px;">
                <span class="ml-2 font-bold">{{ errorColaborador }}</span>
                <button @click="errorColaborador = ''"><i
                    class="fas fa-times text-lg cursor-pointer text-gray-800 hover:bg-red-300 px-2 rounded-sm"></i></button>
              </div>
            </div>


          </div>
        </div>

        <h2 class="text-blue-500 font-bold mb-2 mt-2">Datos Personales</h2>
        <div class="flex">
          <input type="text" placeholder="Nombre" v-model="datosPersonales.client_full_name"
            class="border-gray-300 border-b-2 w-1/4 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800 mr-4">
          <input type="text" placeholder="Apellido" v-model="datosPersonales.client_surname"
            class="border-gray-300 border-b-2 w-1/4 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800">
        </div>
        <input @input="validateDNI" type="text" placeholder="DNI / CI" v-model="datosPersonales.client_dni"
          class="border-gray-300 border-b-2 w-1/4 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800 mr-4">
        <input type="number" placeholder="Celular" v-model="datosPersonales.client_phone"
          class="border-gray-300 border-b-2 w-1/4 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800">
        <div class="flex">
          <input type="text" placeholder="Email" v-model="datosPersonales.client_mail"
            class="border-gray-300 border-b-2 w-1/4 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800">
        </div>
        <div v-if="error" class="text-red-500 bg-red-100 p-2 rounded-md text-center flex justify-between items-center"
          style="width: 460px;">
          <span class="ml-2 font-bold">{{ error }}</span>
          <button @click="error = ''"><i
              class="fas fa-times text-lg cursor-pointer text-gray-800 hover:bg-red-300 px-2 rounded-sm"></i></button>
        </div>


        <h2 class="text-blue-500 font-bold mb-2 mt-2">Datos de Facturación</h2>
        <div class="flex">
          <input type="text" placeholder="Nombre para facturación" v-model="datosFacturacion.name"
            class="border-gray-300 border-b-2 w-1/4 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800 mr-4">
          <input type="text" placeholder="Número de identificación tributaria" v-model="datosFacturacion.num_tributario"
            class="border-gray-300 border-b-2 w-1/4 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800">
        </div>
        <input type="text" placeholder="Dirección de facturación" v-model="datosFacturacion.direccion"
          class="border-gray-300 border-b-2 w-1/4 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800 mr-4">
        <input type="text" placeholder="Email de facturación" v-model="datosFacturacion.email"
          class="border-gray-300 border-b-2 w-1/4 focus:outline-none p-2 pl-0 mb-6 placeholder-gray-800">


        <div class="text-center mt-4">
          <button @click="guardarDatos"
            class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 font-bold rounded-full"><i
              class="fas fa-save mr-2"></i>Guardar mis datos</button>
        </div>
      </div>
    </div>
  </main>

  <ModalComponent :isVisible="showModal" @close="showModal = false" />
  <ModalComponentColaborador :isVisible="showModalColaborador" @close="showModalColaborador = false" />
</template>

<script>

import ModalComponent from '../components/ModalComponent.vue';
import ModalComponentColaborador from '../components/ModalComponentColaborador.vue';
import axios from 'axios';
import { getCookie } from '../helpers/cookies';

export default {
  emits: ['login', 'logout'],
  props: ['user'],
  components: {
    ModalComponent,
    ModalComponentColaborador
  },
  data() {
    return {
      sidebarOpen: false,
      showInfoMessage: false,
      showInfoMessageColaborador: false,
      datosPersonales: {},
      datosFacturacion: {},
      error: '',
      showModal: false,
      showModalColaborador: false,
      loading: true,
      invite_mail: '',
      errorColaborador: ''
    };
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    logout() {
      this.$emit('logout');
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    toggleInfo() {
      this.showInfoMessage = !this.showInfoMessage;
    },
    validateDNI() {
      const regex = /^[0-9./-]*$/;

      if (!regex.test(this.datosPersonales.client_dni)) {
        this.datosPersonales.client_dni = this.datosPersonales.client_dni.replace(/[^0-9./-]/g, '');
      }
    },
    async inviteCollaborator() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.invite_mail) {
        this.errorColaborador = 'Por favor, ingresa un correo electrónico.';
      } else if (!emailRegex.test(this.invite_mail)) {
        this.errorColaborador = 'Por favor, ingresa un correo electrónico válido.';
      } else {
        const headers = {
          'Content-Type': 'application/json',
          'x-api-key': getCookie('token')
        };
        let body = {
          email: this.invite_mail
        };
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/api/generateInviteLink`, body, { headers })

        if (result.status === 200) {
          this.invite_mail = '';
          this.errorColaborador = '';
          this.showModalColaborador = true;
        } else if (result.status === 201) {
          this.errorColaborador = 'El colaborador ya se encuentra en un equipo.';
        } else {
          this.errorColaborador = 'Error al invitar al colaborador.';
        }
      }
    },
    async guardarDatos() {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': getCookie('token')
      };

      const dni = this.datosPersonales.client_dni.replace(/[^0-9]/g, '') ?? '';
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      const telefonoNumero = this.datosPersonales.client_phone;

      if (!this.datosPersonales.client_business_name || !this.datosPersonales.client_full_name ||
        !this.datosPersonales.client_surname || !this.datosPersonales.client_dni ||
        !this.datosPersonales.client_phone ||
        !this.datosPersonales.client_mail) {
        this.error = 'Por favor, completa todos los campos.';
      } else if (dni.length < 6 || dni.length > 8) {
        this.error = 'El DNI debe contener entre 6 y 8 números.';
      } else if (telefonoNumero.length < 8 || telefonoNumero.length > 30) {
        this.error = 'El número debe contener entre 8 y 12 caracteres.';
      } else if (!emailRegex.test(this.datosPersonales.client_mail)) {
        this.error = 'Por favor, ingresa un correo electrónico válido.';
      } else {
        this.error = '';
        let body = {
          client_full_name: this.datosPersonales.client_full_name,
          client_surname: this.datosPersonales.client_surname,
          client_dni: this.datosPersonales.client_dni,
          client_phone: this.datosPersonales.client_phone,
          client_mail: this.datosPersonales.client_mail,
          client_business_name: this.datosPersonales.client_business_name,
          fac_name: this.datosFacturacion.name ?? null,
          fac_tribu: this.datosFacturacion.num_tributario ?? null,
          fac_direccion: this.datosFacturacion.direccion ?? null,
          fac_email: this.datosFacturacion.email ?? null
        };

        await axios.post(`${process.env.VUE_APP_API_URL}/api/modifyUserData`, body, { headers })
        this.showModal = true;
      }
    },
    fetchUserData() {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': getCookie('token')
      };

      axios.get(`${process.env.VUE_APP_API_URL}/api/userData`, { headers })
        .then(response => {
          this.datosPersonales = {
            client_full_name: response.data.client_full_name,
            client_surname: response.data.client_surname,
            client_dni: response.data.client_dni,
            client_phone: response.data.client_phone,
            client_mail: response.data.client_mail,
            client_business_name: response.data.client_business_name,
          };
          this.datosFacturacion = {
            name: response.data.fac_name ?? null,
            num_tributario: response.data.fac_tribu ?? null,
            direccion: response.data.fac_direccion ?? null,
            email: response.data.fac_email ?? null
          };
          this.loading = false;
        })
        .catch(error => {
          console.error('Error al obtener todas las solicitudes:', error);
          this.loading = false;
        });
    },
    beforeDestroy() {
      document.removeEventListener('click', this.closeDropdownOnClickOutside);
    }
  }
}
</script>
