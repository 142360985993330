import { useAuth0 } from '@auth0/auth0-vue';

export const authenticationGuard = async (to, from, next) => {
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

    // Wait for Auth0 to finish loading
    while (isLoading.value) {
        await new Promise(resolve => setTimeout(resolve, 100)); // Polling every 100ms
    }

    // Once loading is complete, check if the user is authenticated
    if (isAuthenticated.value) {
        next();
    } else {
        loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }
};
