<template>
  <main class="flex p-4 pt-0 lg:ml-56 bg-gray-100">
    <div class="rounded-2xl w-full overflow-x-auto bg-white">
      <h1 class="text-5xl font-bold text-center mt-4" style="color: #032141;">Elige el mejor plan para
        ti.</h1>
      <h2 class="text-2xl font-bold text-center mt-4" style="color: #032141;">Sin permanencia. Sin costos
        ocultos.<br>Pagás solo por las firmas
        que vas a consumir.</h2>

      <div class="flex justify-center mt-16 mb-12">
        <div class="w-1/4 px-1 text-center my-6">
          <div class="px-4 border-2 flex flex-col items-center justify-between h-full rounded-lg"
            style="border: 2px solid #FEBFCC;">
            <div class="flex-grow flex flex-col items-center">
              <h2 class="mt-4 font-bold" style="color: #005941;">Salve una Planta</h2>
              <h3 class="font-bold text-2xl" style="color: #032141;">-Pack de 25 firmas-</h3>
              <p class=" mt-4 font-semibold" style="color: #2A6465;">u$d</p>
              <p class="font-semibold" style="color: #2A6465;"><span class="text-5xl">25</span>final</p>
              <p class="font-semibold" style="color: #2A6465;"><span class="text-2xl">‎ </span></p>
              <p class="mt-4 font-semibold">Ideal para profesionales e inmobiliarias<br>‎ </p>
              <ul class="text-left px-4 text-sm mt-8 text-black-800 list-disc">
                <li class="mb-1">u$d 1,00 final por firma</li>
                <li class="my-1">Plantilla de WhatsApp personalizada</li>
                <li class="my-1">Plataforma centralizada de firmas</li>
              </ul>
            </div>
            <button class="custom-button py-2 px-6 my-8 font-semibold text-sm rounded-lg"
              @click="handleClick(25)">COMPRAR
              25</button>
          </div>
        </div>
        <div class="w-1/4 px-1 text-center relative">
          <div
            class="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-500 py-1 px-3 rounded z-10 text-gray-100 text-sm"
            style="top: -0.3rem; background-color: #4BA589;">#máspopular</div>
          <div class="px-4 border-2 flex flex-col items-center justify-between h-full rounded-lg"
            style="border: 2px solid #FEBFCC; background-color: #FEBFCC;">
            <div class="mt-6 flex-grow flex flex-col items-center">
              <h2 class="mt-4 font-bold" style="color: #005941;">Salve un Árbol</h2>
              <h3 class="font-bold text-2xl" style="color: #032141;">-Pack de 50 firmas-</h3>
              <p class="mt-4 font-semibold" style="color: #2A6465;">u$d</p>
              <p class="font-semibold" style="color: #2A6465;"><span class="text-5xl">39</span>final</p>
              <p class="font-semibold" style="color: #2A6465;"><span class="text-2xl">22% descuento</span></p>
              <p class="mt-4 font-semibold">Ideal para pequeñas empresas<br>‎ </p>
              <ul class="text-left px-4 text-sm mt-8 text-black-800 list-disc">
                <li class="mb-1">u$d 0,78 final por firma</li>
                <li class="my-1">Plantilla de WhatsApp personalizada</li>
                <li class="my-1">Plataforma centralizada de firmas</li>
                <li class="mt-1">Soporte online</li>
              </ul>
            </div>
            <button class="custom-button py-2 px-6 my-14 font-semibold text-sm rounded-lg"
              @click="handleClick(50)">COMPRAR
              50</button>
          </div>

          <h2 class="font-bold text-center mt-4" style="color: #032141;">* El cobro se realizará en tu moneda local.
          </h2>

        </div>
        <div class=" w-1/4 px-1 text-center my-6">
          <div class="px-6 border-2 flex flex-col items-center justify-between h-full rounded-lg"
            style="border: 2px solid #FEBFCC;">
            <div class=" flex-grow flex flex-col items-center">
              <h2 class="mt-4 font-bold" style="color: #005941;">Salve un Bosque</h2>
              <h3 class="font-bold text-2xl" style="color: #032141;">-Pack de 100 firmas-</h3>
              <p class="mt-4 font-semibold" style="color: #2A6465;">u$d</p>
              <p class="font-semibold" style="color: #2A6465;"><span class="text-5xl">59</span>final</p>
              <p class="font-semibold" style="color: #2A6465;"><span class="text-2xl">41% descuento</span></p>
              <p class="mt-4 font-semibold">Ideal para medianas empresas y organizaciones</p>
              <ul class="text-left px-4 text-sm mt-8 text-black-800 list-disc">
                <li class="mb-1">u$d 0,59 final por firma</li>
                <li class="my-1">Plantilla de WhatsApp personalizada</li>
                <li class="my-1">Plataforma centralizada de firmas</li>
                <li class="my-1">Soporte online</li>
                <li class="my-1">API de integración</li>
              </ul>
            </div>
            <button class="custom-button py-2 px-6 my-8 font-semibold text-sm rounded-lg"
              @click="handleClick(100)">COMPRAR
              100</button>
          </div>

        </div>

      </div>
    </div>
  </main>
</template>

<script>

import axios from 'axios';
import { getCookie } from '../helpers/cookies';

export default {
  emits: ['login', 'logout'],
  props: ['user'],

  data() {
    return {
      sidebarOpen: false
    };
  },
  methods: {
    logout() {
      this.$emit('logout');
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    async handleClick(amount) {
      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': getCookie('token')
      };
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/generatePaiementDlocal?amount=${amount}`, { headers });
        const redirectUrl = response.data.url;
        window.open(redirectUrl, '_blank');
      } catch (error) {
        console.error('API call failed:', error);
      }
    }
  }
}
</script>


<style>
.custom-button {
  background-color: #4BA589;
  color: white;
  border: 1px solid #4BA589;
}

.custom-button:hover {
  background-color: white;
  color: #4BA589;
  border: 1px solid #4BA589;
}
</style>