import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/LoginView.vue';
import Purchase from '../views/PurchaseView.vue';
import Requests from '../views/RequestsView.vue';
import CreateDocument from '../views/CreateDocumentView.vue';
import Reports from '../views/ReportsView.vue';
import Contacts from '../views/ContactsView.vue';
import Profile from '../views/ProfileView.vue';
import NotFound from '../views/NotFoundView.vue';
import Test from '../views/TestView.vue';
import Carga from '../views/CargaView.vue';
import { authenticationGuard } from '../helpers/authChecker'; // Import the auth checker


const routes = [
  {
    path: '/',
    component: Login
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/purchase',
    component: Purchase,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/requests',
    component: Requests,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/newdocument',
    component: CreateDocument,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/reports',
    component: Reports,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/contacts',
    component: Contacts,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/profile',
    component: Profile,
    beforeEnter: authenticationGuard,
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  },
  {
    path: '/test',
    component: Test
  },
  {
    path: '/carga',
    component: Carga,
    beforeEnter: authenticationGuard,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});


export default router;